@import '~@/styles/variables.scss';

.header {
  background-color: $blue;
  border-bottom: solid 9px $red;

  .container {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 80px;
    }

    .localeSwitcher {
      color: $white;
      margin: 0;
      text-transform: capitalize;
    }

    @media (max-width: $screen-xs-max) {
      justify-content: space-between;
    }  
  }
}
