// Colours.

$white: #fff;
$whiteGrey: #fefefe;
$blackLight: #3f3f3f;
$black: #000;
$blue: #2283c5;
$red: #c0311a;

// Breakpoints.

$screen-xs: 560px;
$screen-xs-margin: 40px;

$screen-sm: 768px;
$screen-sm-margin: 60px;

$screen-md: 992px;
$screen-md-margin: 80px;

$screen-xl: 1200px;
$screen-xl-margin: 120px;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-xl - 1);

$breakpoints: (
  "xs": $screen-xs,
  "sm": $screen-sm,
  "md": $screen-md,
  "xl": $screen-xl,
);