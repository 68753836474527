@import '~@/styles/variables.scss';

.footer {
  background-color: $whiteGrey;
  padding: 2.5em 0em;

  .main {
    margin-bottom: 2.5em;

    address {
      font-style: normal;

      .or {
        margin: 0 0.3rem;
      }

      a {
        color: $blackLight;
      }
    }
  }

  .links {
    margin: 1em 0;

    a {
      font-weight: 600;
    }
  }

  .socials a {
    margin-right: 0.4rem;
  }
}
