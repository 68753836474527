@import '~@/styles/variables.scss';

.promo {
  padding: 2.5em 0 1.5em;
  background-color: $red;
  background-image: url('/assets/cta/cta-background.svg');
  background-size: cover;
  color: $white;

  .badges {
    margin-top: 1px;
  }
}